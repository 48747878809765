<script>
	import '../../assets/scss/skybox.scss';

	export default {
		name: "page-header",
		computed: {
			header() {
				return this.$store.state['skyboxHeader']
			},
			navigationVisible() {
				return this.$store.state['navigationVisible'];
			}
		},
		methods: {
			goBack()  {
				this.$router.go(-1)
			}
		}
	}
</script>

<template>
    <header>
			<div class="header-container">
				<div class="overlay"></div>
				<div class="top">
					<div class="logo">
						<a href="/">MVM Dome</a>
					</div>

				</div>

				<div class="texts" v-if="header">
					<router-link :to="header.backLink" v-if="header.backLink && header.backLink !== 'history-back'">
						<font-awesome-icon icon="fa-solid fa-angle-left" />
						<span v-t="header.backLinkText"></span>
					</router-link>
					<a @click="goBack()" v-if="header.backLink && header.backLink == 'history-back'">
						<font-awesome-icon icon="fa-solid fa-angle-left" />
						<span v-t="header.backLinkText"></span>
					</a>
					<div class="title">
						{{ $t(header.title) }}
					</div>
					<div class="subtitle">
						<p>
							{{ header.description }}
						</p>
					</div>
				</div>
			</div>
		</header>
</template>