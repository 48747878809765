<script>
import GlobalCart from './components/global-cart';
import PageHeader from './components/skybox/page-header';
import './assets/css/fradi-icons.css';
import OrderProgressDisplay from './components/order-progress-display.vue';

export default {
  components: { GlobalCart, PageHeader, OrderProgressDisplay },
  data() {
    return {
      cartHeight: 0,
      scrollTop: 0,
      lastClick: null,
    };
  },
  computed: {
    appLoading() {
      return this.$store.state.appLoading;
    },
    showCart() {
      const route = this.$route.name,
        isCartEmpty = this.$store.state.cart.items.length === 0;
      return !isCartEmpty && (route === 'buffet-list' || route === 'buffet-subpage');
    },
    isBuffetRoute() {
      return this.$route.meta && this.$route.meta.buffet !== undefined ? this.$route.meta.buffet : false;
    },
    routeName() {
      return this.$route.name;
    },
    appCssClass() {
      let obj = { 'cart-is-open': this.showCart };
      obj[this.routeName + '-page'] = true;

      return obj;
    },
  },
  watch: {
    showCart() {
      if (this.showCart && this.$el) {
        if (this.$el.offsetHeight < this.lastClickClientY + this.cartHeight) {
          // if the opening cart would cover the position of previous click
          if (this.$el.offsetHeight + this.$el.scrollTop + this.cartHeight >= this.$el.scrollHeight) {
            // if scrolled down to the bottom,
            // can’t scroll any further until the height is increased
            setTimeout(() => {
              // scroll down after height changed
              this.updateScrollPosition();
            }, 330);
          } else {
            // scroll down immediately
            this.updateScrollPosition();
          }
        }
      }
    },
  },
  mounted() {
    this.$store.commit('setApploading', true);

    this.cartHeight = 0;
    if (this.$refs.globalCart !== undefined) {
      this.cartHeight = this.$refs.globalCart.$el.offsetHeight;
    }

    setTimeout(() => {
      this.$store.commit('setApploading', false);
    }, 3000);
  },
  methods: {
    routeHasComponent(componentName) {
      return this.$route.meta && this.$route.meta.frame && this.$route.meta.frame.includes(componentName);
    },
    updateScrollPosition() {
      this.$el.scrollBy({ top: this.cartHeight, behavior: 'smooth' });
    },
  },
};
</script>
<template>
  <div :class="{ 'app-loading': true }">
    <transition>
      <div class="app-loader" v-if="appLoading"></div>
    </transition>

    <div id="app" v-if="isBuffetRoute" :class="appCssClass" :style="{ '--cart-height': cartHeight + 'px' }" @click.capture="lastClickClientY = $event.clientY">
      <page-header v-if="routeHasComponent('buffet-header')"></page-header>
      <main>
        <order-progress-display v-if="routeHasComponent('pending-orders')"></order-progress-display>
        <transition name="fade" mode="out-in">
          <router-view :id="$route.name" />
        </transition>
      </main>
      <global-cart ref="globalCart" v-if="routeHasComponent('cart')" :type="'buffet'"></global-cart>
    </div>
    <div
      id="app"
      v-else
      :class="{ 'cart-is-open': showCart }"
      :style="{ '--cart-height': cartHeight + 'px' }"
      @click.capture="lastClickClientY = $event.clientY"
    >
      <page-header v-if="routeHasComponent('skybox-header')"></page-header>
      <main>
        <transition name="fade" mode="out-in">
          <router-view :id="$route.name" />
        </transition>
      </main>
      <global-cart ref="globalCart" v-if="routeHasComponent('cart')" :type="'skybox'"></global-cart>
    </div>
  </div>
</template>
