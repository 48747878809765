<template>
    <div class="order-progress-component">
        <div class="circle" v-if="hasPendingOrders" @click="navigateToPendingOrders()">
            <span></span> {{ $tc("btn-active-orders", pendingOrderCount)}}
        </div>
    </div>
</template>>

<script>
export default {
    name: 'order-progress-display',
    computed: {
        pendingOrderCount() {
            return this.$store.state.pendingOrders.length;
        },
        hasPendingOrders() {
            return this.$store.state.pendingOrders.length > 0;
        }
    },
    created() {
        this.$store.dispatch('loadPendingOrders');
    },
    methods: {
        navigateToPendingOrders() {
            return this.$router.push('/track-orders');
        }
    }
}
</script>