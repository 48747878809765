<script>
import { mapState } from 'vuex';
	import cartItem from "./skybox/cart/cart-item.vue";
	export default {
		components: { cartItem },
		props: {
			type: String	
		},
		data() {
			return {
				isOpen: false
			}
		},
		computed: {
			...mapState(['selectedProductType']),
			cart() {
				return this.$store.state.cart;
			},
			hasItems() {
				return this.$store.state.cart.items.length > 0;
			},
			totalGrossPrice() {
				return this.$store.getters.totalPrice;
			},
			totalNetPrice() {
				return this.$store.getters.totalNetPrice;
			},
			totalVatAmount() {
				return this.$store.getters.totalVatAmount;
			}
		},
		methods: {
			toggle() {
				this.isOpen = !this.isOpen;
			},
			goToDrinks() {
				this.$store.commit('setProductType', 'drink');
				window.scrollTo({top: 320, behavior: 'smooth'});
			}
		}
	}
</script>
<template>
	<div class="global-cart" :class="{open: isOpen}">
		<div class="indicator" @click="toggle" v-if="hasItems">
			<div class="circle">
				<font-awesome-icon icon="fa-solid fa-caret-up" v-if="!isOpen" />
				<font-awesome-icon icon="fa-solid fa-caret-down" v-if="isOpen" />
			</div>
		</div>
		<div class="cart-items" v-if="hasItems">
			<h5>{{ $t('cart-cart-contents') }}</h5>
			<transition-group name="slide-fade">
				<cart-item
						v-for="item in cart.items"
						:key="item.id"
						:item="item"
						:hasQtyButtons="true"
				></cart-item>
			</transition-group>
				<div class="summary">
					<div class="cart-line">
						<div class="text">{{ $t('cart-net-price') }}</div>
						<div class="value">{{$n(totalNetPrice, 'currency')}}</div>
					</div>
					<div class="cart-line">
						<div class="text">{{ $t('cart-vat-amount') }} </div>
						<div class="value">{{$n(totalVatAmount, 'currency')}}</div>
					</div>
					<div class="cart-line">
						<div class="text">{{ $t('cart-total-gross-price') }}</div>
						<div class="value">{{$n(totalGrossPrice, 'currency')}}</div>
					</div>
				</div>
		</div>
		<div class="action">
			<button v-if="type == 'skybox' && selectedProductType == 'food'" @click="goToDrinks()" class="yellowBtn large" style="text-align: center">
				<span class="text">
					{{ $t('go-to-drinks') }}
				</span>
				
				<span class="overall-price">
					{{$n(totalGrossPrice, 'currency')}}
				</span>
			</button>

			<router-link to="/overview" v-if="hasItems && selectedProductType == 'drink' || type == 'buffet'">
				<button class="yellowBtn large">
					<span class="text">
						{{$t('checkout-button')}}
					</span>
					
					<span class="overall-price">
						{{$n(totalGrossPrice, 'currency')}}
					</span>
				</button>
			</router-link>
		</div>
	</div>
</template>
<style scoped>
	.indicator {
		cursor: pointer;
		text-align: center;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
	}
	.indicator .icon {
		display: inline-block;
		transition: all 200ms;
	}
	.global-cart.open .indicator .icon {
		transform: rotate(180deg);
	}
	.head {
		transition: all .3s;
		display: flex;
		padding: 22px 0 26px;
	}
	.head > h3 {
		margin: 0;
	}
	.head > h3:first-of-type {
		flex: 1;
		font-weight: normal;
	}

	.global-cart:not(.open) .cart-items {
		z-index: -1;
		overflow: hidden;
		max-height: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.action {
		margin: 0 -20px;
	}
</style>